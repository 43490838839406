import { NgOptimizedImage } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'

import { isEqual, omit } from 'lodash'

import { ShapeComponent } from '#modules/workspace/components/element/shape'
import { IImageSetting, IShapeSetting, ISize } from '#modules/workspace/types/element'
import { defaultAdjustmentColor } from '#modules/workspace/workspace.service'
import { getFlipScale } from '#shared/utils/image'

@Component({
  selector: 'ace-image-view',
  standalone: true,
  template: `
    <div class="relative select-none" draggable="false" [style.opacity]="setting().opacity">
      <ace-shape [selected]="selected()" [setting]="shapeSetting()" [size]="size()" [scale]="scale()">
        <div
          class="absolute left-0 top-0"
          [style.width]="containerSize().width + 'px'"
          [style.height]="containerSize().height + 'px'"
          [style.transform]="'translate(' + containerTranslate().x + 'px, ' + containerTranslate().y + 'px)'"
        >
          <div class="relative z-20" [style.transform]="imageTransform()">
            <img
              #imageRef
              draggable="false"
              class="relative z-10 h-full max-h-none w-full max-w-none select-none"
              [ngSrc]="imageSrc()"
              [alt]="setting().alt"
              [fill]="true"
              [priority]="true"
              [loaderParams]="{ original: true }"
            />
          </div>
        </div>
      </ace-shape>
    </div>
  `,
  styles: ``,
  imports: [ShapeComponent, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewComponent {
  setting = input.required<IImageSetting>()
  size = input.required<ISize>()
  selected = input(false)
  scale = input.required<number>()

  // 根据当前图片的配置返回一个透明的正方形shape
  shapeSetting = computed<IShapeSetting>(() => {
    const imageSetting = this.setting()
    return {
      version: imageSetting.version,
      path: 'M0,0 L256,0 L256,256 L0,256 Z',
      fill: { color: '#ffffff', opacity: 0 },
      blur: 0,
      stroke: omit(
        {
          ...imageSetting.stroke,
          radius: imageSetting.stroke.show ? imageSetting.stroke.radius : 0,
          style: imageSetting.stroke.show ? imageSetting.stroke.style : undefined
        },
        'show'
      ),
      // 阴影使用canvas渲染 保证颜色调整和阴影可以同时设置
      shadow: {
        type: 'outer',
        behind: false,
        ...imageSetting.adjustment.shadow,
        show: false
      }
    }
  })

  containerSize = computed(() => this.setting().size, { equal: isEqual })
  containerTranslate = computed(() => this.setting().transform.translate, { equal: isEqual })
  imageTransform = computed(() => {
    const flip = this.setting().flip
    return getFlipScale(flip)
  })

  adjustment = computed(() => this.setting().adjustment)
  adjustShadow = computed(() => this.adjustment().shadow)
  adjustColor = computed(() => this.adjustment().color)
  adjustFilter = computed(() => this.adjustment().filter)

  resizeImageWidth = computed(
    () => {
      const containerWidth = this.containerSize().width
      // 最大值2400 最小值200
      const range = [2400, 1200, 800, 400, 200]
      return range.find(value => value <= containerWidth) || 200
    },
    { equal: isEqual }
  )

  enableShadow = computed(() => {
    const shadow = this.adjustShadow()
    return shadow.show && [shadow.blur, shadow.offset, shadow.angle].some(value => value > 0)
  })
  enableAdjustmentColor = computed(() => {
    const color = this.adjustColor()
    return color.enable && !isEqual(omit(color, 'enable'), omit(defaultAdjustmentColor.color, 'enable'))
  })
  enableAdjustmentFilter = computed(() => {
    const setting = this.adjustFilter()
    return setting.enable && !isEqual(omit(setting, 'enable'), omit(defaultAdjustmentColor.filter, 'enable'))
  })
  enableCanvas = computed(() => {
    return this.enableShadow() || this.enableAdjustmentColor() || this.enableAdjustmentFilter()
  })
  resizeImageSrc = computed(() => `${this.setting().src}?x-oss-process=image/resize,w_${this.resizeImageWidth() * 2}`, { equal: isEqual })

  imageSrc = computed(
    () => {
      const filterSrc = this.setting().adjustment.src
      return filterSrc && this.enableCanvas() ? this.getFilterImageUrl(filterSrc, this.filterVersion()) : this.resizeImageSrc()
    },
    { equal: isEqual }
  )

  // 用于触发重新渲染
  filterVersion = computed(() => this.setting().adjustment.version || '', { equal: isEqual })

  protected getFilterImageUrl(baseUrl: string, version: string) {
    return `${baseUrl}?version=${version}`
  }
}
