import { CommonModule, NgOptimizedImage } from '@angular/common'
import { HttpEventType } from '@angular/common/http'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, OnDestroy, OnInit, signal, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import { FabricImage, Shadow, StaticCanvas } from 'fabric'
import { produce } from 'immer'
import { cloneDeep, isEqual, mapValues, omit, pick, set, toPairs } from 'lodash'
import { isBoolean, isNumber } from 'lodash-es'
import { asyncScheduler, combineLatestWith, distinctUntilChanged, filter, map, retry, Subscription, throttleTime } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { IPosition } from '@libs/algorithm'
import { Belongs } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { ImageViewComponent } from '#modules/workspace/components/element/image/image-view.component'
import { ShapeComponent } from '#modules/workspace/components/element/shape'
import { ChangeAction } from '#modules/workspace/store'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { IImageSetting, ISize } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ProjectService } from '#shared/services/project/project.service'
import { parseSingleToString } from '#shared/utils/color'
import { getAllFilterSettings, getFlipScale } from '#shared/utils/image'

const computedEqual = { equal: isEqual }

async function generateHash(message: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}

@Component({
  selector: 'ace-image',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, ShapeComponent],
  templateUrl: './image.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent extends ImageViewComponent implements OnDestroy, OnInit, AfterViewInit {
  elementId = input.required<string>()
  uiStore = inject(StageUiStore)
  apiService = inject(ApiService)
  projectService = inject(ProjectService)
  workspaceService = inject(WorkspaceService)

  // 保存未选中时的调整信息，在取消选中时比较信息是否改变，如果改变需要重新上传图片
  adjustmentBeforeUnSelected = signal<Pick<IImageSetting['adjustment'], 'shadow' | 'filter' | 'color'> | undefined>(void 0)
  // 记录resize前的图片大小，在交互时如果大于此size那么图片进行缩放，如果小于那么对图片进行裁剪
  beforeResizingSize = signal({ width: 0, height: 0 }, { equal: isEqual })
  // 临时的图片位置，保存交互时的信息
  temporaryTranslate = signal({ x: 0, y: 0 }, { equal: isEqual })
  // 临时的图片大小，保存交互时的信息
  temporaryImageSize = signal({ width: 0, height: 0 }, { equal: isEqual })
  fabricImage = signal<FabricImage | undefined>(undefined)
  fabricCanvas = signal<StaticCanvas | undefined>(undefined)

  // 滤镜是否正在上传
  filterUploading = signal(false)
  // 调整初始化
  adjustmentInitialized = signal(false)

  currentVersionHash = signal('')
  /**
   * 真实需要判断是否添加订阅的select状态，如果是助选，此时虽然被选中但是不需要渲染canvas
   */
  realSelected = computed(() => this.selected() && this.uiStore.selectedTarget() === 'element' && this.uiStore.selectedIds().length === 1)
  flip = computed(() => this.setting().flip)

  /**
   * 生成新的canvas调整信息
   */
  adjustCanvasSetting = computed(() => {
    const filterEnable = this.enableAdjustmentFilter()
    const colorEnable = this.enableAdjustmentColor()
    return {
      ...mapValues(omit(this.adjustColor(), 'enable'), value => (colorEnable && value ? value : 0)),
      ...mapValues(omit(this.adjustFilter(), 'enable'), value => filterEnable && value)
    }
  }, computedEqual)
  /**
   * 画布中的交互状态
   */
  isPageResizing = computed(() => this.uiStore.isResizingElement() === ChangeAction.Page)
  /**
   * 右侧配置项的修改状态
   */
  isSettingResizing = computed(() => this.uiStore.isResizingElement() === ChangeAction.Setting)
  currentTranslate = computed(() => this.setting().transform.translate, computedEqual)

  /**
   * 重写container translate/size
   * 交互组件交互式需要使用临时位置信息
   */
  override containerTranslate = computed(() => (this.isPageResizing() ? this.temporaryTranslate() : this.currentTranslate()), computedEqual)
  override containerSize = computed(() => (this.isPageResizing() ? this.temporaryImageSize() : this.currentImageSize()), computedEqual)
  currentImageSize = computed(() => this.setting().size, computedEqual)

  imageFlip = computed(() => getFlipScale(this.flip()))

  originalContainerSize = computed(() => {
    const scale = this.scale()
    const containerSize = this.containerSize()
    return {
      width: containerSize.width * scale,
      height: containerSize.height * scale
    }
  })
  /**
   * 图片的宽高比
   */
  imageRatio = computed(() => this.setting().size.width / this.setting().size.height)

  willUpload = computed(() => {
    return this.currentVersionHash() !== this.filterVersion() && this.enableCanvas()
  })

  showCanvas = computed(() => {
    const selected = this.realSelected()
    if (selected) {
      return this.enableCanvas()
    } else {
      return this.willUpload() || this.filterUploading()
    }
  })

  showImage = computed(() => {
    return !this.showCanvas() || !this.adjustmentInitialized()
  })

  /**
   * 加入图片有过调整那么需要渲染一个canvas
   */

  canvasRef = viewChild<ElementRef<HTMLCanvasElement>>('canvasRef')

  adjustCanvasSetting$ = toObservable(this.adjustCanvasSetting)
  isPageResizing$ = toObservable(this.isPageResizing)
  isSettingResizing$ = toObservable(this.isSettingResizing)
  size$ = toObservable(this.size).pipe(distinctUntilChanged(isEqual))
  realSelected$ = toObservable(this.realSelected)
  canvasRef$ = toObservable(this.canvasRef)
  fabricImage$ = toObservable(this.fabricImage)
  // 当canvas隐藏时取消订阅
  adjustmentStop$ = this.canvasRef$.pipe(filter(ref => !ref))
  containerSize$ = toObservable(this.containerSize)
  shadowSetting$ = toObservable(this.adjustShadow)
  resizeImageSrc$ = toObservable(this.resizeImageSrc)
  originalContainerSize$ = toObservable(this.originalContainerSize)
  _subscription = new Subscription()

  constructor() {
    super()
    // 取消选中时会导致当前的elementId为undefined，需要在选中时缓存
    this.realSelected$.pipe(takeUntilDestroyed()).subscribe(async selected => {
      console.log('selected', selected)
      if (selected) {
        // 缓存当前的调整信息
        this.cacheAdjustment()
        this.addResizeListener()
      } else {
        // 如果当前有添加的滤镜，那么生成图片上传
        if (this.willUpload()) {
          const filterSrc = this.setting().adjustment.src
          const fileName = filterSrc ? filterSrc.substring(filterSrc.lastIndexOf('/') + 1) : this.generateFilterImagePath()
          this.uploadFilterImage(fileName, this.currentVersionHash())
        }
      }
    })

    // 监听canvas dom出现的时机 表明现在需要使用fabric初始化staticCanvas用于滤镜的渲染
    this.addCanvasRefListener()

    // 每次调整时需要同时计算出当前调整信息的hash值用于在取消选中时进行上传判断
    toObservable(this.adjustment)
      .pipe(takeUntilDestroyed())
      .subscribe(adjustment => {
        this.hashAdjustment(adjustment).then(hash => {
          // console.log('hash start', Date.now())
          this.currentVersionHash.set(hash)
          // console.log('hash end', Date.now(), hash)
        })
      })

    // 修改flip会同时修改translate中的定位信息，用于保持裁剪框中的内容进行翻转而不是全部图片
    toObservable(this.flip)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        // 每次翻转需要重新缓存当前的位置信息用于交互时进行正确的裁剪操作
        this.initPosition()
      })
  }

  ngOnInit() {
    this.initPosition()
    this.cacheAdjustment()
  }

  ngOnDestroy() {
    this._subscription.unsubscribe()
  }

  ngAfterViewInit() {
    const filterSrc = this.setting().adjustment.src
    if (filterSrc) {
      // 初始化后检测一次图片中filterSrc中携带的elementId是否相同，如果不相同那么表示当前元素通过复制等途径生成，此时需要在此图片的基础上复制一张新的图片，避免因为source的改变导致当前图片也被改变
      const [elementId] = filterSrc.substring(filterSrc.lastIndexOf('/') + 1, filterSrc.lastIndexOf('.')).split('_')
      if (elementId && this.elementId() !== elementId) {
        // 生成一张新的filterImage
        // path 去除域名
        const path = filterSrc.replace(/https?:\/\/[^/]+\//, '')
        // fork
        this.apiService.forkImage({ projectId: this.projectService.projectId, path, destPath: this.generateFilterImagePath() }).subscribe({
          next: image => {
            // 保存当前配置
            this.uiStore.setSettingElement(
              this.elementId(),
              produce(this.setting(), draft => {
                draft.adjustment.src = image.link
              })
            )
            // 跳过历史记录
            this.uiStore.resetInteractingElement({ skipHistory: true })
          }
        })
      }
    }
  }

  private initPosition() {
    // 初始化当前shadow信息
    this.temporaryImageSize.set(cloneDeep(this.setting().size))
    this.temporaryTranslate.set(cloneDeep(this.setting().transform.translate))
    this.beforeResizingSize.set(cloneDeep(this.size()))
  }

  /**
   * 初始化滤镜
   * 第一次渲染和阴影一起渲染
   * @param fabricCanvas
   * @param fabricImage
   * @private
   */
  private initFilters(fabricCanvas: StaticCanvas, fabricImage: FabricImage) {
    return new Promise<void>(resolve => {
      const currentFilters = fabricImage.filters
      if (!currentFilters) {
        // 初始化当前fabricImage的滤镜数组
        fabricImage.filters = []
      }

      // 统一render方法
      const render = () => {
        console.count('apply filters')
        fabricImage?.applyFilters()
        fabricCanvas?.requestRenderAll()
      }

      const filterSettings = getAllFilterSettings()
      // 根据全部的滤镜效果进行的单独订阅设置
      filterSettings.forEach((setting, index) => {
        const { colorKey, filterInstance, valueKey, fixedValue } = setting
        this.adjustCanvasSetting$
          .pipe(
            takeUntil(this.adjustmentStop$),
            takeUntil(this.fabricImage$.pipe(filter(image => !!image && image !== fabricImage))),
            // 根据当前的adjustment信息生成不同的source
            map(color => color[colorKey]),
            // 重复数据跳过
            distinctUntilChanged(),
            // 100ms内只触发一次 并 保证最后一次触发
            throttleTime(100, asyncScheduler, { trailing: true })
          )
          .subscribe(value => {
            console.log('value change', colorKey, value)
            if (value && ((isNumber(value) && this.enableAdjustmentColor()) || (isBoolean(value) && this.enableAdjustmentFilter()))) {
              let instance = fabricImage.filters[index]
              if (!instance) {
                instance = filterInstance()
                fabricImage.filters[index] = instance
              }
              if (isNumber(value)) {
                set(instance, valueKey || colorKey, value)
              }
              if (isBoolean(value) && fixedValue) {
                set(instance, valueKey || colorKey, fixedValue)
              }
              // 数组中记录了每一个滤镜是否为第一次初始化，当初始化的状态为false，那么所有的滤镜仅创建不渲染，只有最后一个滤镜判断后进行渲染
              if (setting.initial) {
                render()
              }
            } else {
              if (fabricImage.filters[index]) {
                delete fabricImage.filters[index]
                render()
              }
            }
            // 如果是一次加载 那么渲染的调用的父级中
            if (!setting.initial && index === filterSettings.length - 1) {
              resolve()
            }

            // 修改状态 下一次变更时进行渲染
            setting.initial = true
          })
      })
    })
  }

  /**
   * 添加resize监听
   * @private
   */
  private addResizeListener() {
    let updated = false
    this.size$
      .pipe(combineLatestWith(this.isPageResizing$, this.isSettingResizing$), takeUntil(this.realSelected$.pipe(filter(select => !select))))
      .subscribe(([interactionSize, isPageResizing, isSettingResizing]) => {
        const currentImageSize = this.currentImageSize()
        const currentTranslate = this.currentTranslate()

        console.log(interactionSize, isPageResizing, isSettingResizing, currentImageSize, currentTranslate)
        // 在画布中进行交互
        if (isPageResizing) {
          updated = true
          const handle = this.uiStore.interacting.resizing.handler()
          console.log('resizing', handle, interactionSize, currentImageSize, currentTranslate)
          this.handleResizing(handle, interactionSize, currentImageSize, currentTranslate)
        }
        // 在右侧配置项中进行交互
        if (isSettingResizing) {
          // 通过非拖拽方式修改的值，比如输入框, 比较当前size的宽高，如果宽度变化，那么handle为e，如果高度变化那么handle为s
          const before = this.beforeResizingSize()
          // 阈值0.01
          const handle = Math.abs(before.width - interactionSize.width) > 0.01 ? 'e' : Math.abs(before.height - interactionSize.height) > 0.01 ? 's' : undefined
          console.log('handle', handle, before, interactionSize)
          if (handle) {
            updated = true
            this.handleResizing(handle, interactionSize, currentImageSize, currentTranslate)
          }
        }

        // 当交互停止后统一提交一次数据并生成undo
        if (!isSettingResizing && !isPageResizing && updated) {
          updated = false
          console.log('reset', this.elementId(), this.setting(), this.temporaryImageSize(), this.temporaryTranslate())
          this.uiStore.setSettingElement(this.elementId(), {
            ...this.setting(),
            size: this.temporaryImageSize(),
            transform: {
              ...this.setting().transform,
              translate: this.temporaryTranslate()
            }
          })
          // 提交当前的变更信息
          this.uiStore.resetInteractingElement()
          // 完成后将状态信息重新保存到shadow中以便下一次使用
          requestAnimationFrame(() => {
            this.initPosition()
          })
        }
      })
  }

  /**
   * 添加canvasRef监听，当canvas显示时开始初始化fabricCanvas并加载图片，当图片的shadow和filter初始化后将图片添加到canvas中并开启第一次渲染
   * @private
   */
  private addCanvasRefListener() {
    return this.canvasRef$.pipe(combineLatestWith(this.resizeImageSrc$), takeUntilDestroyed()).subscribe(([canvasRef, resizeImageSrc]) => {
      if (canvasRef) {
        let currentCanvas = this.fabricCanvas()
        if (!currentCanvas) {
          currentCanvas = new StaticCanvas(canvasRef.nativeElement, {
            selection: false,
            enableRetinaScaling: false,
            renderOnAddRemove: false
          })
          this.fabricCanvas.set(currentCanvas)
          console.log('fabricCanvas', currentCanvas)
        }

        FabricImage.fromURL(resizeImageSrc, {
          crossOrigin: 'anonymous'
        }).then(fabricImage => {
          if (fabricImage) {
            Promise.all([this.initFilters(currentCanvas, fabricImage), this.initResize(currentCanvas, fabricImage)]).then(() => {
              const currentImage = this.fabricImage()
              if (currentImage) {
                currentCanvas.remove(currentImage)
              }
              currentCanvas.add(fabricImage)
              this.fabricImage.set(fabricImage)
              fabricImage.applyFilters()
              currentCanvas.renderAll()
              this.adjustmentInitialized.set(true)
            })
          }
        })
      } else {
        this.fabricCanvas()
          ?.dispose()
          .then(() => {
            this.fabricCanvas.set(undefined)
            this.fabricImage.set(undefined)
            this.adjustmentInitialized.set(false)
          })
      }
    })
  }

  /**
   * 监听裁剪信息，渲染shadow效果
   * @param fabricCanvas
   * @param fabricImage
   * @private
   */
  private initResize(fabricCanvas: StaticCanvas, fabricImage: FabricImage) {
    let initialized = false
    return new Promise<void>(resolve => {
      this.originalContainerSize$
        .pipe(
          combineLatestWith(this.shadowSetting$),
          takeUntil(this.adjustmentStop$),
          takeUntil(this.fabricImage$.pipe(filter(image => !!image && image !== fabricImage)))
        )
        .subscribe(([size, shadow]) => {
          fabricCanvas.setDimensions(size)
          if (shadow.show) {
            let shadowInstance = fabricImage.shadow
            // 根据角度计算dx和dy
            const dx = shadow.offset * Math.cos((shadow.angle * Math.PI) / 180)
            const dy = shadow.offset * Math.sin((shadow.angle * Math.PI) / 180)
            if (!shadowInstance) {
              shadowInstance = new Shadow({
                color: parseSingleToString(shadow.color),
                blur: shadow.blur,
                offsetX: dx,
                offsetY: dy
              })
              fabricImage.shadow = shadowInstance
            } else {
              shadowInstance.color = parseSingleToString(shadow.color)
              shadowInstance.blur = shadow.blur
              shadowInstance.offsetX = dx
              shadowInstance.offsetY = dy
            }
            // 根据阴影的偏移角度距离及模糊重新计算image处于canvas中的位置及大小
            const blurRange = shadow.blur
            const { width: containerWidth, height: containerHeight } = size
            const { width: imageWidth, height: imageHeight } = fabricImage
            // 当x轴上的偏移大于模糊程度，那么left永远为0，如果小于模糊程度，那么dx可能为正数或者负数，此时减去模糊程度并计算绝对值就是left的值
            const left = dx - blurRange > 0 ? 0 : Math.abs(dx - blurRange)
            // 和left同理
            const top = dy - blurRange > 0 ? 0 : Math.abs(dy - blurRange)
            const afterWidth = imageWidth + Math.abs(dx) + blurRange + left
            const afterHeight = imageHeight + Math.abs(dy) + blurRange + top
            // 根据添加shadow后的大小在container内进行缩放 保证shadow的效果可以完全显示
            const scale = Math.min(containerWidth / afterWidth, containerHeight / afterHeight)
            fabricImage.scale(scale)
            // 计算缩放后x y上剩余的位置并微调
            const offsetX = (containerWidth - afterWidth * scale) / 2
            const offsetY = (containerHeight - afterHeight * scale) / 2
            fabricImage.set({
              left: left * scale + offsetX,
              top: top * scale + offsetY
            })
          } else {
            // 删除阴影时需要重置位置
            fabricImage.shadow = null
            fabricImage.set({
              left: 0,
              top: 0
            })
            fabricImage.scaleToWidth(size.width)
            fabricImage.scaleToHeight(size.height)
          }
          if (!initialized) {
            initialized = true
            resolve()
          } else {
            fabricCanvas.requestRenderAll()
          }
        })
    })
  }

  /**
   * 上传需要保存的滤镜图片
   * @param fileName
   * @param hash
   * @private
   */
  private uploadFilterImage(fileName: string, hash: string) {
    console.log('upload filter image', fileName)
    const canvas = this.fabricCanvas()
    const elementId = this.elementId()
    if (canvas) {
      // 获取base64字符串信息
      const dataUrl = canvas.toDataURL({
        format: 'png',
        multiplier: this.resizeImageWidth() / this.containerSize().width,
        quality: 1,
        enableRetinaScaling: true
      })

      // 使用fetch将base64转化为blob
      fetch(dataUrl)
        .then(res => res.blob())
        .then(async blob => {
          const file = new File([blob], fileName, { type: 'image/png' })

          this.filterUploading.set(true)

          // 模拟上传并返回一张新图片
          this.apiService
            .uploadImage(file, this.projectService.projectId, fileName, Belongs.Project)
            .pipe(retry(3))
            .subscribe({
              next: event => {
                if (event.type === HttpEventType.Response) {
                  const filterImageUrl = event.body
                  const imageLoader = new Image()
                  imageLoader.src = this.getFilterImageUrl(filterImageUrl, hash)
                  imageLoader.onload = () => {
                    this.cacheAdjustment()
                    this.filterUploading.set(false)

                    // 保存信息的滤镜url和version
                    this.uiStore.setSettingElement(
                      elementId,
                      produce(this.setting(), draft => {
                        draft.adjustment.src = filterImageUrl
                        draft.adjustment.version = hash
                      })
                    )
                    // 提交当前的变更信息
                    this.uiStore.resetInteractingElement({ skipHistory: true })
                  }
                  imageLoader.onerror = () => {
                    this.filterUploading.set(false)
                  }
                }
              },
              error: error => {
                // 重试
                console.error('filter image upload error', error)
              }
            })
        })
    }
  }

  /**
   *  获取一个滤镜的path路径 保证在不同地方使用的path相同
   *  elementId - 当前时间 - 随机字符串
   * @private
   */
  private generateFilterImagePath() {
    return `${this.elementId()}_${Date.now()}_${String(Math.random().toString(36).slice(2))}.png`
  }

  /**
   * 缓存当前调整信息
   * @private
   */
  private cacheAdjustment() {
    const setting = this.setting()
    this.adjustmentBeforeUnSelected.set(cloneDeep(pick(setting.adjustment, ['shadow', 'filter', 'color'])))
  }

  /**
   * 处理画布中不同的handler下image的裁剪效果
   * @param handle 东南西北
   * @param interactionSize 交互中边框的实时大小
   * @param currentImageSize 交互中的图片大小
   * @param currentTranslate 交互中的图片相对位置
   * @private
   */
  private handleResizing(handle: unknown, interactionSize: ISize, currentImageSize: ISize, currentTranslate: IPosition) {
    if (handle === 'e') {
      // 右
      const left = Math.abs(currentTranslate.x)
      if (left + interactionSize.width > currentImageSize.width) {
        let diffHeight: number, offsetLeft: number
        this.temporaryImageSize.update(
          produce(draft => {
            const diff = interactionSize.width - (currentImageSize.width - left)
            const scale = interactionSize.width / (currentImageSize.width - left)
            offsetLeft = left * (scale - 1)
            const increaseWidth = diff + offsetLeft
            diffHeight = increaseWidth / this.imageRatio()
            draft.width = currentImageSize.width + increaseWidth
            draft.height = currentImageSize.height + diffHeight
          })
        )

        this.temporaryTranslate.update(
          produce(draft => {
            draft.y = this.currentTranslate().y - diffHeight / 2
            draft.x = currentTranslate.x - offsetLeft
          })
        )
      }
    } else if (handle === 'w') {
      const left = Math.abs(currentTranslate.x)
      const right = currentImageSize.width - left - this.beforeResizingSize().width
      let diffHeight: number, offsetRight: number
      if (interactionSize.width - this.beforeResizingSize().width > left) {
        // 放大
        const diff = interactionSize.width - this.beforeResizingSize().width - left
        const scale = interactionSize.width / (this.beforeResizingSize().width + left)
        offsetRight = right * (scale - 1)
        const increaseWidth = diff + offsetRight
        diffHeight = increaseWidth / this.imageRatio()
        this.temporaryImageSize.update(
          produce(draft => {
            draft.width = currentImageSize.width + increaseWidth
            draft.height = currentImageSize.height + diffHeight
          })
        )
        this.temporaryTranslate.update(
          produce(draft => {
            draft.x = 0
            draft.y = this.currentTranslate().y - diffHeight / 2
          })
        )
      } else {
        this.temporaryTranslate.update(
          produce(draft => {
            draft.x = -(left - (interactionSize.width - this.beforeResizingSize().width))
          })
        )
      }
    } else if (handle === 'n') {
      // 上
      const top = Math.abs(currentTranslate.y)
      const bottom = currentImageSize.height - top - this.beforeResizingSize().height
      if (interactionSize.height - this.beforeResizingSize().height > top) {
        // 放大
        const diff = interactionSize.height - this.beforeResizingSize().height - top
        const scale = interactionSize.height / (this.beforeResizingSize().height + top)
        const offsetBottom = bottom * (scale - 1)
        const increaseHeight = diff + offsetBottom
        const diffWidth = increaseHeight * this.imageRatio()
        this.temporaryImageSize.update(
          produce(draft => {
            draft.height = currentImageSize.height + increaseHeight
            draft.width = currentImageSize.width + diffWidth
          })
        )
        this.temporaryTranslate.update(
          produce(draft => {
            draft.y = 0
            draft.x = this.currentTranslate().x - diffWidth / 2
          })
        )
      } else {
        this.temporaryTranslate.update(
          produce(draft => {
            draft.y = -(top - (interactionSize.height - this.beforeResizingSize().height))
          })
        )
      }
    } else if (handle === 's') {
      const top = Math.abs(currentTranslate.y)
      if (top + interactionSize.height > currentImageSize.height) {
        let diffWidth: number, offsetTop: number
        this.temporaryImageSize.update(
          produce(draft => {
            const diff = interactionSize.height - (currentImageSize.height - top)
            const scale = interactionSize.height / (currentImageSize.height - top)
            offsetTop = top * (scale - 1)
            const increaseHeight = diff + offsetTop
            diffWidth = increaseHeight * this.imageRatio()
            draft.height = currentImageSize.height + increaseHeight
            draft.width = currentImageSize.width + diffWidth
          })
        )

        this.temporaryTranslate.update(
          produce(draft => {
            draft.x = this.currentTranslate().x - diffWidth / 2
            draft.y = currentTranslate.y - offsetTop
          })
        )
      }
    } else {
      console.warn('handle不需要处理')
    }
  }

  /**
   * 计算adjustment的hash值用于对比
   * @param adjustment
   * @private
   */
  private hashAdjustment(adjustment: IImageSetting['adjustment']) {
    // const source = pick(setting.adjustment, ['color', 'filter', 'shadow'])
    const { filter: filterSetting, shadow, color } = adjustment

    return filterSetting.enable || shadow.show || color.enable
      ? generateHash(
          [
            filterSetting.enable ? toPairs(omit(filterSetting, 'enable')) : [],
            shadow.show ? toPairs(omit(shadow, 'show')) : [],
            color.enable ? toPairs(omit(color, 'enable')) : []
          ]
            .flat()
            .map(([key, value]) => `${key}:${value}`)
            .join(',')
        )
      : Promise.resolve('')
  }
}
