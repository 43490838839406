<div class="relative">
  @if ((!editing() || preview()) && hasStroke()) {
    <p
      class="-z-1 pointer-events-none absolute select-none text-transparent"
      [style.font-size.px]="fontSize()"
      [style.textAlign]="paraSetting().textAlign"
      [style.lineHeight]="paraSetting().lineHeight"
      [style.minWidth.px]="!horizontalMode() ? lineHeight : 'unset'"
      [style.minHeight.px]="horizontalMode() ? lineHeight : 'unset'"
      [style.width.px]="size().width"
      [style.height.px]="size().height"
    >
      @for (char of paraSetting().chars; let index = $index; track index) {
        <ace-text-char
          [charSetting]="char"
          [textSetting]="textSetting()"
          [editing]="editing()"
          [horizontalMode]="horizontalMode()"
          [hasStroke]="hasStroke()"
          [shadowDom]="true"
          [preview]="preview()"
        ></ace-text-char>
      }
    </p>
  }

  <p
    class="top-p"
    [style.font-size.px]="fontSize()"
    [style.textAlign]="paraSetting().textAlign"
    [style.lineHeight]="paraSetting().lineHeight"
    [style.minWidth.px]="!horizontalMode() ? lineHeight : 'unset'"
    [style.minHeight.px]="horizontalMode() ? lineHeight : 'unset'"
    [style.width.px]="size().width"
    [style.height.px]="size().height"
  >
    @for (char of paraSetting().chars; let index = $index; track index) {
      <ace-text-char
        [charSetting]="char"
        [textSetting]="textSetting()"
        [editing]="editing()"
        [horizontalMode]="horizontalMode()"
        [hasStroke]="hasStroke()"
        [shadowDom]="false"
        [preview]="preview()"
      ></ace-text-char>
    }
  </p>
</div>
