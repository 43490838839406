<div #interactDiv class="origin-top-left" [style.scale]="scale()" [style.width.px]="size().width" [style.height.px]="size().height">
  @switch (category()) {
    @case ('text') {
      <ace-text [id]="id()" [setting]="textSetting()" [size]="size()" [selected]="selected()" [preview]="preview()" [locked]="locked()"></ace-text>
    }
    @case ('chart') {
      <ace-chart [setting]="chartSetting()" [size]="size()" [selected]="selected()"></ace-chart>
    }
    @case ('shape') {
      <ace-shape [setting]="shapeSetting()" [selected]="selected()" [size]="size()" [scale]="scale()"></ace-shape>
    }
    @case ('image') {
      <ace-image [setting]="imageSetting()" [selected]="selected()" [size]="size()" [elementId]="id()" [scale]="scale()"></ace-image>
    }
    @case ('line') {
      <ace-line [setting]="lineSetting()" [selected]="selected()" [size]="size()"></ace-line>
    }
    @default {
      <div></div>
    }
  }
</div>
