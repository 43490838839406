<div class="relative select-none" draggable="false" [style.opacity]="setting().opacity">
  <ace-shape [selected]="selected()" [setting]="shapeSetting()" [size]="size()" [scale]="scale()">
    <div
      class="absolute left-0 top-0"
      [style.width]="containerSize().width + 'px'"
      [style.height]="containerSize().height + 'px'"
      [style.transform]="'translate(' + containerTranslate().x + 'px, ' + containerTranslate().y + 'px) rotate(' + (setting().transform.rotation || 0) + 'deg)'"
    >
      <div class="relative z-20 h-full w-full" [style.transform]="imageFlip()">
        @if (showCanvas()) {
          <div class="absolute z-20 origin-top-left" [style.transform]="'scale(' + 1 / scale() + ')'">
            <canvas #canvasRef></canvas>
          </div>
        }
        @if (showImage()) {
          <img
            #imageRef
            draggable="false"
            class="relative z-10 h-full max-h-none w-full max-w-none select-none"
            [ngSrc]="imageSrc()"
            [alt]="setting().alt"
            [fill]="true"
            [priority]="true"
            [loaderParams]="{ original: true }"
          />
        }
      </div>
    </div>
    <!-- 显示裁剪时的分割线 -->
    <ng-content></ng-content>
  </ace-shape>
</div>
