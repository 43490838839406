import { CommonModule, DOCUMENT } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, inject, input, OnInit, output, signal } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import { ElementService } from '#modules/workspace/services/element.service'
import { TextService } from '#modules/workspace/services/text.service'
import { ChangeAction } from '#modules/workspace/store'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { TextStore } from '#modules/workspace/store/text.store'
import { ISize, ITextSetting } from '#modules/workspace/types/element'

import { TextCharComponent } from './text-char/text-char.component'
import { TextParagraphComponent } from './text-paragraph/text-paragraph.component'

@Component({
  selector: 'ace-text',
  standalone: true,
  imports: [CommonModule, TextCharComponent, TextParagraphComponent],
  templateUrl: './text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextComponent implements AfterViewInit {
  horizontalMode = computed(() => this.setting().direction === 'horizontal-tb')

  uiStore = inject(StageUiStore)
  textStore = inject(TextStore)
  textService = inject(TextService)
  elementService = inject(ElementService)

  id = input.required<string>()
  preview = input<boolean>(false)
  locked = input<boolean>(false)

  // TODO remove all local size
  size = input.required<ISize>()
  size$ = toObservable(this.size).pipe(takeUntilDestroyed())

  setting = input.required<ITextSetting>()

  selected = input<boolean>(false)
  selected$ = toObservable(this.selected).pipe(takeUntilDestroyed())

  document = inject(DOCUMENT)

  editing = computed<boolean>(() => {
    return this.textStore.editing() && this.textStore.id() === this.id()
  })

  constructor() {
    this.size$.subscribe(size => {
      // TODO size 传入的不是shadowData中的size
      // console.log(this.uiStore.isResizingElement())
      if (!this.selected() || this.uiStore.isResizingElement() !== ChangeAction.Page) return

      queueMicrotask(() => this.textService.resizeText('size'))
    })

    // only trigger after unselect
    let prevStatus = false
    this.selected$.subscribe(selected => {
      if (!selected && prevStatus) {
        this.inactive()
      }
      prevStatus = selected
    })
  }

  ngAfterViewInit() {
    if (this.preview()) return

    if (this.textStore.autoEditing() && this.selected()) {
      this.onEditText()
      this.textStore.updateAutoEditing(false)
    }
  }

  onEditText(e: MouseEvent | null = null) {
    if (
      this.preview() ||
      !this.textStore.element() ||
      this.editing() ||
      this.uiStore.isMovingElement() === ChangeAction.Page ||
      // this.textStore.hasParent() ||
      this.textStore.multipleSelected()
    )
      return

    this.textStore.updateEditPosition({ x: e?.clientX || 0, y: e?.clientY || 0 })
    this.textStore.updateShadowSetting(this.setting())
    this.textStore.updateEditing(true)
  }

  inactive() {
    this.textStore.updateRange(null)
    this.textStore.updateEditing(false)

    if (this.setting().paragraphs.length === 1 && this.setting().paragraphs[0].chars.length === 1 && this.setting().paragraphs[0].chars[0].text.trim() === '') {
      this.elementService.deleteElements(this.id())
    }
  }

  onDragStart(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
  }
}
