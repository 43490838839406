import { abs } from 'mathjs'
import { bufferToggle, filter, fromEvent, interval, map, merge, tap } from 'rxjs'

// 模拟点击事件，mousedown 500ms 内没有移动鼠标，mouseup 时触发点击事件
export function customClick(options: {
  interval?: number
  element: HTMLElement
  onMousedown?: ($event: MouseEvent) => void
  onMouseup?: ($event: MouseEvent) => void
  onMousemove?: ($event: MouseEvent) => void
}) {
  return fromEvent<MouseEvent>(options.element, 'mouseup').pipe(
    bufferToggle(
      fromEvent<MouseEvent>(options.element, 'mousedown').pipe(
        tap(event => {
          if (options.onMousedown) {
            options.onMousedown(event)
          }
        })
      ),
      () =>
        merge(
          fromEvent<MouseEvent>(document, 'mousemove', { capture: true }).pipe(
            filter(event => abs(event.movementX) > 0 || abs(event.movementY) > 0),
            tap(events => {
              if (options.onMousemove) {
                options.onMousemove(events)
              }
            })
          ),
          fromEvent<MouseEvent>(options.element, 'mouseup').pipe(
            tap(events => {
              if (options.onMouseup) {
                options.onMouseup(events)
              }
            })
          ),
          interval(options.interval || 500)
        )
    ),
    filter(events => events.length === 1),
    map(events => events[0])
  )
}
