<div
  #text
  class="w-max whitespace-break-spaces break-words"
  [style.writing-mode]="setting().direction"
  [class.selected-text]="selected() && !preview()"
  [class.pointer-events-none]="preview()"
>
  <div (click)="onEditText($event)" [class.pointer-events-none]="!selected()">
    @for (paragraph of setting().paragraphs; let i = $index; track i) {
      <ace-text-paragraph
        [textSetting]="setting()"
        [paraSetting]="paragraph"
        [editing]="editing()"
        [preview]="preview()"
        [horizontalMode]="horizontalMode()"
        [size]="size()"
      ></ace-text-paragraph>
    }
  </div>
</div>

<!--
  当 div contenteditable=true 时，使用方向键向上跨 p 移动光标，会跳过 p 中以 \n 结尾的行
  在同一个 p 中，或者使用 <br> 换行，或者自动换行，都不会有这个问题
-->
