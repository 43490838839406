import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, effect, HostBinding, input } from '@angular/core'

import { IParaSetting, ISize, ITextSetting } from '#modules/workspace/types/element'

import { TextCharComponent } from '../text-char/text-char.component'

@Component({
  selector: 'ace-text-paragraph',
  standalone: true,
  imports: [CommonModule, TextCharComponent],
  templateUrl: './text-paragraph.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextParagraphComponent {
  @HostBinding('class') class = 'block'

  textSetting = input.required<ITextSetting>()
  paraSetting = input.required<IParaSetting>()
  editing = input<boolean>(false)
  horizontalMode = input.required<boolean>()
  preview = input<boolean>(false)

  size = input.required<ISize>()

  hasStroke = computed(() => this.paraSetting().chars.some(char => char.textStroke))

  fontSize = computed(() => {
    return Math.max(...this.paraSetting().chars.map(char => char.fontSize))
  })

  lineHeight = computed(() => {
    return this.paraSetting().lineHeight * this.fontSize()
  })

  // constructor() {
  //   effect(() => {
  //     console.log(this.size())
  //   })
  // }
}
