import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Injector,
  input,
  OnDestroy,
  Output,
  output,
  runInInjectionContext,
  signal,
  ViewChild,
  viewChild
} from '@angular/core'

import { DoublyLinkedListNode } from 'doubly-linked-list-typed'
import { Subscription } from 'rxjs'

import { IPosition } from '@libs/algorithm'

import { customClick } from '#core/utils/mouseEvent'
import { ChartComponent } from '#modules/workspace/components/element/chart/chart.component'
import { ImageComponent } from '#modules/workspace/components/element/image/image.component'
import { LineComponent } from '#modules/workspace/components/element/line/line.component'
import { ShapeComponent } from '#modules/workspace/components/element/shape/shape.component'
import { TextComponent } from '#modules/workspace/components/element/text/text.component'
import { GroupElementTreeNode } from '#modules/workspace/models/element-node'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { TextStore } from '#modules/workspace/store/text.store'
import { AtomType, IChartSetting, IImageSetting, ILineSetting, ISetting, IShapeSetting, ISize, ITextSetting } from '#modules/workspace/types/element'

@Component({
  selector: 'ace-element',
  standalone: true,
  imports: [CommonModule, TextComponent, ChartComponent, ShapeComponent, ImageComponent, LineComponent],
  templateUrl: './element-base.component.html',
  styleUrl: './element-base.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementBaseComponent<T extends AtomType> implements OnDestroy {
  id = input.required<string>()
  parent = input<string>()
  category = input.required<T>()
  setting = input.required<ISetting>()
  size = input.required<ISize>()
  preview = input<boolean>(false)

  /**
   * 当前元素的自身缩放比例
   */
  scale = input.required<number>()

  locked = input<boolean>(false)
  position = input<IPosition>({ x: 0, y: 0 })
  edit = output<string | false>()
  dragStart = output<{ id: string; $event: { clientX: number; clientY: number } }>()

  uiStore = inject(StageUiStore)
  textStore = inject(TextStore)
  interactDivRef = viewChild<ElementRef>('interactDiv')

  selected = computed(() => {
    return this.uiStore.selectedIds().includes(this.id()) && this.uiStore.selectedTarget() === 'element'
  })

  chartSetting = computed(() => {
    return this.setting() as IChartSetting
  })

  textSetting = computed(() => {
    return this.setting() as ITextSetting
  })

  shapeSetting = computed(() => {
    return this.setting() as IShapeSetting
  })

  imageSetting = computed(() => {
    return this.setting() as IImageSetting
  })

  lineSetting = computed(() => {
    return this.setting() as ILineSetting
  })

  parentElement = computed(() => {
    const page = this.uiStore.onStagePage()
    const parentId = this.parent()
    if (page && parentId) {
      return page.getElementNodeById(parentId) as GroupElementTreeNode
    } else {
      return undefined
    }
  })

  // textInstance = viewChild(TextComponent)
  imageInstance = viewChild(ImageComponent)
  // shapeInstance = viewChild(ShapeComponent)
  // chartInstance = viewChild(ChartComponent)
  // lineInstance = viewChild(LineComponent)

  private subscription = new Subscription()

  constructor() {
    effect(() => {
      if (this.interactDivRef()) {
        const selected = this.selected()
        this.subscription.unsubscribe()
        this.subscription = new Subscription()
        this.subscription.add(
          customClick({
            element: (this.interactDivRef() as ElementRef).nativeElement,
            onMousedown: $event => {
              if (this.locked()) return

              const parent = this.parent()
              if (!parent) {
                // 如果当前元素为根元素，直接选中
                if (!selected) {
                  this.selectElement($event)
                }
              } else {
                // 子元素不处理shift键按下时的鼠标事件
                if ($event.shiftKey) {
                  return
                }
                // 如果当前元素为组合元素的子元素，在父元素和兄弟元素都没有选中的情况下，选中当前元素
                if (this.uiStore.selectedIdsSet().has(parent)) {
                  const siblings = this.parentElement()?.children
                  if (!siblings?.find(child => this.uiStore.selectedIdsSet().has(child.id))) {
                    this.selectElement($event)
                  }
                }
              }

              // 触发拖拽事件
              if (!this.textStore.editing() && $event.button === 0) {
                this.dragStart.emit({
                  id: this.id(),
                  $event: { clientX: $event.clientX, clientY: $event.clientY }
                })
              }
            }
          }).subscribe($event => {
            if ($event.shiftKey) {
              if (this.parent()) {
                return
              }

              if (selected) {
                this.unselectElement()
              }
            } else {
              if ((this.parent() || this.locked()) && !selected) {
                this.selectElement($event)
              }
            }
          })
        )
      }
    })
  }

  @HostBinding('style.width.px') get width() {
    return this.size().width * this.scale()
  }

  @HostBinding('style.height.px') get height() {
    return this.size().height * this.scale()
  }

  @HostListener('mousedown', ['$event']) protected onMouseDown($event: MouseEvent) {
    if (!this.parent() && !this.locked()) {
      $event.stopPropagation()
    }
  }

  @HostListener('mouseup', ['$event']) protected onMouseUp($event: MouseEvent) {
    $event.stopPropagation()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  protected unselectElement() {
    this.uiStore.removeSelection(this.id())
  }

  protected selectElement($event: MouseEvent) {
    if (this.selected()) return
    const parent = this.parentElement()

    if (parent) {
      if (this.locked()) {
        // 如果当前元素为组合元素的子元素，且元素锁定，选中父元素
        this.uiStore.resetSelection('element', parent.id)
      } else {
        // 如果当前元素为组合元素的子元素，需要清空其他兄弟元素的选中状态
        const siblings = parent.children
        const selectedIds = this.uiStore.selectedIds().filter(id => {
          return id !== parent.id && !siblings.find(child => child.id !== id)
        })
        selectedIds.push(this.id())
        this.uiStore.resetSelection('element', ...selectedIds)
      }
    } else {
      if (this.uiStore.selectedTarget() === 'element') {
        if ($event.shiftKey && !this.uiStore.selectedRootElements()[0].locked) {
          this.uiStore.addSelection(this.id())
        } else {
          this.uiStore.resetSelection('element', this.id())
        }
      } else {
        this.uiStore.resetSelection('element', this.id())
      }
    }
  }
}
