<!--
    It must be placed in front, otherwise there will be problems with the position
  -->
@if (shadowDom()) {
  <span
    class="pointer-events-none select-none text-transparent"
    [style.font-size.px]="charSetting().fontSize"
    [style.font-weight]="charSetting().fontWeight"
    [style.font-family]="charSetting().fontFamily"
    [style.font-style]="charSetting().fontStyle"
    [style.text-rendering]="'geometricPrecision'"
    [style.letterSpacing.em]="charSetting().letterSpacing"
    [style.-webkit-text-stroke]="charSetting().textStroke ? charSetting().textStrokeWidth / 200 + 'em ' + parseColor(charSetting().textStrokeColor) : 'unset'"
    [style.text-shadow]="
      textSetting().textShadow
        ? parseColor(textSetting().textShadowColor) + ' ' + parseShadow('x') + 'em ' + parseShadow('y') + 'em ' + parseShadow('blur') + 'em'
        : 'unset'
    "
    [style.text-decoration]="charSetting().textDecoration.join(' ')"
    >{{ charSetting().text }}</span
  >
} @else {
  <span
    [class]="isGradientColor() ? 'bg-clip-text' : ''"
    [style.color]="(editing() && !preview()) || isGradientColor() ? 'transparent' : parseColor(charSetting().color)"
    [style.background-image]="isGradientColor() ? parseColor(charSetting().color) : 'none'"
    [style.font-size.px]="charSetting().fontSize"
    [style.font-weight]="charSetting().fontWeight"
    [style.font-family]="charSetting().fontFamily"
    [style.font-style]="charSetting().fontStyle"
    [style.text-rendering]="'geometricPrecision'"
    [style.text-decoration]="charSetting().textDecoration.join(' ')"
    [style.letterSpacing.em]="charSetting().letterSpacing"
    [style.text-shadow]="
      !hasStroke() && textSetting().textShadow && !(editing() && !preview())
        ? parseColor(textSetting().textShadowColor) + ' ' + parseShadow('x') + 'em ' + parseShadow('y') + 'em ' + parseShadow('blur') + 'em'
        : 'unset'
    "
    >{{ charSetting().text }}</span
  >
}
