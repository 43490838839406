import { CommonModule, DOCUMENT } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, Renderer2 } from '@angular/core'

import { HexToRGBA } from 'packages/charts/src/lib/helperComponents'

import { IColor } from '@editorup/charts'

import { ICharSetting, ITextSetting } from '#modules/workspace/types/element'
import { isGradientColor, parseGradientToString } from '#shared/utils/color'

@Component({
  selector: 'ace-text-char',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-char.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextCharComponent {
  el = inject(ElementRef)
  renderer = inject(Renderer2)
  document = inject(DOCUMENT)

  charSetting = input.required<ICharSetting>()
  textSetting = input.required<ITextSetting>()
  editing = input.required<boolean>()
  horizontalMode = input.required<boolean>()
  hasStroke = input.required<boolean>()
  shadowDom = input.required<boolean>()
  preview = input<boolean>(false)

  isGradientColor = computed(() => {
    return isGradientColor(this.charSetting().color)
  })

  constructor() {}

  // @HostBinding('class') get class() {
  //   return this.shadowDom() ? 'pointer-events-none select-none text-transparent' : this.isGradientColor() ? 'bg-clip-text' : ''
  // }

  // @HostBinding('style') get style() {
  //   if (this.shadowDom()) {
  //     return {
  //       fontSize: `${this.charSetting().fontSize}px`,
  //       fontWeight: this.charSetting().fontWeight,
  //       fontStyle: this.charSetting().fontStyle,
  //       fontFamily: this.charSetting().fontFamily,
  //       textDecoration: this.charSetting().textDecoration,
  //       '-webkit-text-stroke': this.hasStroke() ? `${this.charSetting().textStrokeWidth}px ${this.parseColor(this.charSetting().textStrokeColor)}` : 'none',
  //       textShadow: this.textSetting().textShadow
  //         ? this.parseColor(this.textSetting().textShadowColor) +
  //           ` ${this.parseShadow('x')}px ${this.parseShadow('y')}px ${this.textSetting().textShadowBlur}px`
  //         : 'unset'
  //     }
  //   } else {
  //     return {
  //       fontSize: `${this.charSetting().fontSize}px`,
  //       fontWeight: this.charSetting().fontWeight,
  //       fontStyle: this.charSetting().fontStyle,
  //       fontFamily: this.charSetting().fontFamily,
  //       textDecoration: this.charSetting().textDecoration,
  //       color: (this.editing() && !this.interact() && !this.hasStroke()) || this.isGradientColor() ? 'transparent' : this.parseColor(this.charSetting().color),
  //       backgroundImage: this.isGradientColor() ? this.parseColor(this.charSetting().color) : 'none',
  //       textShadow:
  //         !this.hasStroke() && this.textSetting().textShadow && !this.interact()
  //           ? this.parseColor(this.textSetting().textShadowColor) +
  //             ` ${this.parseShadow('x')}px ${this.parseShadow('y')}px ${this.textSetting().textShadowBlur}px`
  //           : 'unset'
  //     }
  //   }
  // }

  // parseColor = HexToRGBA
  parseColor(color: IColor) {
    if (isGradientColor(color)) {
      return parseGradientToString(color.color)
    } else {
      return HexToRGBA(color)
    }
  }

  parseShadow(prop: 'x' | 'y' | 'blur') {
    const firstFontSize = this.textSetting().paragraphs[0].chars[0].fontSize
    const fontSize = this.charSetting().fontSize

    const angle = this.textSetting().textShadowAngle // 阴影角度（以度为单位）
    const distance = ((this.textSetting().textShadowOffset / 100) * firstFontSize) / fontSize // 偏移距离

    // 根据角度计算dx和dy
    if (prop === 'x') return distance * Math.cos((angle * Math.PI) / 180)
    else if (prop === 'y') return distance * Math.sin((angle * Math.PI) / 180)
    else return ((this.textSetting().textShadowBlur / 100) * firstFontSize) / fontSize
  }
}
